.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 75px;
    transition: opacity 0.50s, visibility 0.50s;
}

body {
    background-color: #000;
}

* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* .spinner{
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.50s, visibility 0.50s;
}

.spinner-grow{
    width: 50px;
    height: 50px;
    color: white;
} */