.home-navbar {
    transition: 0.5s ease;
    letter-spacing: 0.5px;
}

.home-navbar:hover {
    border-bottom: 2px solid white;
}


.home-navbar-scrolled {
    background-color: #000;
    border-bottom: 2px solid white;
}

.navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-toggler {
    border: 0;
}