.common-logo {
    max-width: 250px;
}

.nav-logo-color {
    max-width: 250px;
}

.loginbtn{
    cursor: pointer;
    text-decoration: none;
    color: white;
    
}

.dropdown-toggle{
    border: none;
}

.dropdown-menu{
    border: none;
    background-color: black;
    min-width: 200px;
}

.common-navbar {
    border-bottom: 2px solid white;  
    background: #000;
    transition: 0.5s ease;
    letter-spacing: 0.5px;
}

.navbar:hover {
    background: #000;
}

@media screen and (min-width: 992px) {
    .navbar .active {
        color: rgb(245, 179, 12) !important;
        text-decoration: underline;
        text-underline-offset: 21px;
        text-decoration-thickness: 6px;
    }

    .navbar-link:hover {
        color: white;
        text-decoration: underline;
        text-underline-offset: 21px !important;
        text-decoration-thickness: 6px;
        transition: 1s ease;
    }
}



@media screen and (max-width: 992px) {
    .navbar .active {
        color: rgb(245, 179, 12) !important;
    }

}


@media screen and (min-width: 992px) {
    
    .nav .active {
        color: rgb(245, 179, 12) !important;
        text-decoration: underline;
        text-underline-offset: 21px;
        text-decoration-thickness: 6px;
    }

    .dropdown-menu .navbar-link:hover {
        color: white;
        text-decoration: underline;
        text-underline-offset: 5px !important;
        text-decoration-thickness: 2px;
        transition: 1s ease;
    }
}

