.project {
    position: relative;
    overflow: hidden;
    transition: 0.5s ease;
}

.project-details-bottom {
    position: absolute;
    bottom: 0;
    top: auto;
    width: 100%;
    background: #000;
    opacity: .75;
}

.project-info {
    margin-left: 0.5rem;
    color: white;
}

.project img {
    transition: 0.5s ease;
    opacity: 90%;
    min-width: 100%;
}

.project:hover img {
    -webkit-transform: scale(1.08);
    -ms-transform: scale(1.08);
    transform: scale(1.08);
    transition: 0.5s ease;
    opacity: 100%;
}

.project-details-additional {
    margin-top: 0.5rem;
    color: grey;
}

.project:hover {
    box-shadow: 0 0 20px grey;
}

@media screen and (min-width: 768px) {
    .spotlight-project-section {
        padding: 0 5rem;
    }
}

.spotlight-project {
    overflow: hidden;
    transition: 0.5s ease;
    box-shadow: 0 0 10px grey;
    
   
    
}

.spotlight-project img {

    /* min-width: 100%; */
    opacity: 75%;
    transition: 0.5s ease;
    
}

.spotlight-project:hover img {
    opacity: 100%;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    transition: 0.5s ease;
}

.tab-link {
    color: white;
    font-size: 1.1rem;
}

.tab-pane {
    color: white;
}

.tab-link:hover {
    color: white;
}

.nav-tabs .active {
    border-color: rgb(245, 179, 12) !important;
    color: rgb(245, 179, 12) !important;
    background-color: #000 !important;
    text-decoration: none;
}

