footer {
    
    color: white;
    border-top: 2px solid black;
    padding: 10px 0;
}

.footer-social-icons {
    display: flex;
    justify-content: space-evenly;
}

.fi {
    color: white;
    font-size: 1.3rem;
}

.fi:hover {
    color: rgb(27, 104, 163);
}

.footer-bottom h6 {
    font-size: 1rem;
}