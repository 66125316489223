.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.contact-wrapper {
    color: grey;
}

.contact-wrapper-title p {
    color: rgb(27, 104, 163);
}

.form-control {
    margin-bottom: 12px;
    height: 50px;
}

.form-group textarea {
    height: 100px;
}

.submit-button {
    background-color: rgb(27, 104, 163);
    border-radius: 10px;
}

.contact-info-2 h4 {
    color: grey;
}

.contact-phone {
    /* color: rgb(147, 27, 37); */
    color: grey;
}

.contact-address {
    /* color: rgb(245, 179, 12); */
    color: grey;

}

.contact-email {
    /* color: rgb(27, 104, 163); */
    color: grey;

}

.map-column {
    height: 50vh;
}

.contact-info-2 a:hover {
    text-decoration: underline;
}

.form-label {
    font-size: 20px;
    font-weight: bold;
}


.submit-button a{
    color: black;
}