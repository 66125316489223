.top-btn{
    z-index: 5;
    font-size: 35px;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    color: white;
    cursor: pointer;
}

.top-btn-hide{
    display: none;
    transition: all 1s;
}