#myVideo {
    object-fit: cover;
    width: 100%;
    filter: brightness(70%);
    height: 100vh;
    
}

.home-section {
    position: relative;
    top: -75px;
}

.home-text {
    position: absolute;
    z-index: 2;
    bottom: 15%;
    top: auto;
    color: white;
    margin: 50px;
    width: 45%;
}

.down-arrow {
    position: absolute;
    z-index: 3;
    top: auto;
    bottom: 10%;
    font-size: 35px;
    color: white;
    cursor: pointer;
    transition: all 0.5s;
}

.down-arrow:hover {
    transform: scale(1.3);

}

@media screen and (max-width: 576px) and (min-width: 350px) {
    .home-text h3 {
        font-size: 16px;
    }

    .home-text h1 {
        font-size: 22px;
    }
}

@media screen and (max-width: 768px) and (min-width: 576px) {
    .home-text h3 {
        font-size: 18px;
    }

    .home-text h1 {
        font-size: 25px;
    }
}

@media screen and (max-width: 350px) and (min-width: 200px) {
    .home-text h3 {
        font-size: 12px;
    }

    .home-text h1 {
        font-size: 20px;
    }
}

.animate__animated.animate__slideInLeft {
    --animate-duration: 1s;
}