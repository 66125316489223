.career-card{
    background: linear-gradient(rgb(19, 49, 74), rgb(19, 49, 74));

}

.career-card h4 {
    color: rgb(245, 179, 12);
 }

 

.job-posting {
    max-width: 972px;
    margin: 20px auto;
    padding: 20px;
    background: linear-gradient(rgb(19, 49, 74), rgb(19, 49, 74));
    /* background-color: #f9f9f9; */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.job-posting h1 {
    font-weight: bold;
    color: rgb(245, 179, 12);
}

.job-posting h2 {
    color: grey;
}

.job-posting h3 {
    color: rgb(245, 179, 12);
    margin-top: 15px;
}

.job-posting ol {
    color: white;
}

.job-posting p {
    color: white;
}

.job-posting ul {
    /* list-style-type: none; */
    padding: 0;
}

.job-posting li {
    margin-bottom: 5px;
}

.apply-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 20px;
}

.accordion {
	--bs-accordion-btn-color: rgb(245, 179, 12);
	--bs-accordion-btn-bg: black;
	--bs-accordion-active-color: maroon;
	--bs-accordion-active-bg: lightpink;
    
}

.accordion-button h6{
    color: grey;
}


