.about-content p {
   color: grey;
}

.founder-name {
   color: grey;
}

.about-image {
   box-shadow: 0 0 10px grey;
}

.team-card {
   border: none;
   /* box-shadow: 0 0 5px  grey; */
   transition: 0.5s;
   background-color: #000;
   color: grey;
   cursor: default;
}

.team-card h5 {
   color: rgb(27, 104, 163);
}

.team-card .team-img {
   background-color: grey;
   border-radius: 50%;
   transition: ease 0.5s;
   max-width: 60%;
}

.team-card img {
   border-radius: 50%;
}

.ti {
   color: rgb(27, 104, 163);
}

.team-card h4 {
   text-transform: uppercase;
   transition: 0.5;
}

.team-card:hover {
   background: linear-gradient(rgb(19, 49, 74), rgb(19, 49, 74));
   /* box-shadow: 0 0 5px grey; */
}

.team-card:hover .team-img {
   background-color: rgb(245, 179, 12);
   ;
}

.team-card:hover .ti {
   color: #5face6;
}

.team-card:hover {
   color: white;
}

.team-card:hover h4 {
   color: rgb(245, 179, 12);
}

.team-card:hover h5 {
   color: #5face6;
}

.team-card:hover .team-img {
   transform: scale(1.1);
}

.carousel-inner {
   box-shadow: 0 0 10px grey;
}

@media screen and (min-width: 1200px) {
   .c-item {
      height: 40rem;
   }

   .c-img {
      height: 100%;
      object-fit: cover;
   }

   .carousel-inner {
      width: 60rem;
   }
}

@media screen and (max-width: 1200px) {
   .c-item {
      height: 30rem;
   }

   .c-img {
      height: 100%;
      object-fit: cover;
   }

   .carousel-inner {
      width: 100%;
   }
}

/* For Small Mobile Devices (xs) */
@media screen and (max-width: 576px) {
   .about-image {
      max-width: 75%;
   }

   .about-founder {
      max-width: 30%;
   }
}